// 证券化
<template>
  <div class="financial-securitization">
    <section>
      <div class="trust-title">
        <h3>知识产权证券化模式</h3>
      </div>
      <Info class="securitization-info back-border" />
    </section>
    <section>
      <div class="trust-title">
        <h3>中金浩证券化服务内容</h3>
      </div>
      <el-row class="content-list flex-warp" :gutter="15" type="flex">
        <el-col class="content-item" :span="8" v-for="(item,index) in service.value" :key="index">
          <el-image :src="$base+item.picture"></el-image>
          <p>{{item.name}}</p>
        </el-col>
      </el-row>
    </section>
    <section>
      <div class="trust-title">
        <h3>服务案例</h3>
      </div>
      <el-row class="case-list" :gutter="15">
        <el-col class="case-item " :span="12" v-for="(item,index) in pattern.value" :key="index">
          <p class="back-round">{{item.name}}</p>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import Info from '@/components/Info'
import { getCore } from '@/api'
export default {
  components: {
    Info
  },
  data () {
    return {
      pattern: {},
      service: {}
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getCore('jrcx')
      this.pattern = data[0]
      this.service = data[1]
    }
  },

};
</script>

<style scoped lang="less">
section {
  margin-bottom: 67px;
}
/deep/.trust-title {
  margin-bottom: 27px;
  padding-left: 30px;
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
}
.securitization-info {
  padding: 50px;
  border-radius: 76px;
  line-height: 20px;
}
.content-list {
  .content-item {
    margin: 20px 0;
    text-align: center;
    .el-image {
      margin-bottom: 22px;
    }
  }
}

.case-list {
  padding-left: 50px;
  .case-item {
    padding: 10px 0;
    .back-round {
      position: relative;
      margin-left: 20px;
      color: #221815;
      &::before {
        content: "";
        position: absolute;
        top: 28%;
        left: -20px;
        width: 10px;
        height: 10px;
        background-color: #fcd594;
        border-radius: 50%;
      }
    }
  }
}
</style>
